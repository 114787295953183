// @ts-ignore
import Validator from 'validatorjs'
import Vue from 'vue'

Validator.register(
  'slug',
  (value: string) => {
    const regex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    return !value || (typeof value === 'string' && regex.test(value))
  },
  'Please enter a valid slug'
)

const enMessages = Validator.getMessages('en')
enMessages.required = 'This field is required.'
enMessages.required_if = 'This field is required.'
enMessages.required_if = 'This field is required.'
enMessages.date = 'Please enter a valid date.'
Validator.setMessages('en', enMessages)

const frMessages = Validator.getMessages('fr')
frMessages.required = 'Ce champ est obligatoire.'
frMessages.required_if = 'Ce champ est obligatoire.'
frMessages.date = 'Veuillez entrer une date valide.'
frMessages.slug = 'Veuillez entrer un slug valide.'
Validator.setMessages('fr', frMessages)

export default Vue.extend({
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    validate (inputs: object, rules: Validator.Rules) {
      this.resetErrors()

      const validation = new Validator(inputs, rules)
      const passes = validation.passes()

      if (!passes) {
        this.errors = validation.errors.all()
      }

      return passes
    },
    resetErrors () {
      this.errors = {}
    }
  },
  created () {
    Validator.useLang('fr')
  }
})
